<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
      @click="onClick"
    />
    <land-section
      id="hotcenter"
      space="40"
    >
      <v-container
        style="max-width: 1800px;"
      >
        <v-card
          class="mb-6"
          color="transparent"
          flat
          tile
        >
          <v-tabs
            v-model="currTab"
            background-color="transparent"
            class="d-flex flex-row justify-center align-center"
          >
            <v-tab
              v-for="(tab, tabIndex) in tabList"
              :key="tabIndex"
              :value="tab.name"
              :href="`#${tab.name}`"
              class="text-h6"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
        </v-card>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-tabs-items v-model="currTab">
              <v-tab-item
                value="image"
                class="grey lighten-4"
              >
                <hot-images />
              </v-tab-item>

              <v-tab-item
                value="video"
                class="grey lighten-4"
              >
                <hot-videos />
              </v-tab-item>

              <v-tab-item
                value="article"
                class="grey lighten-4"
              >
                <hot-articles />
              </v-tab-item>
              <v-tab-item
                value="activity"
                class="grey lighten-4"
              >
                <hot-activities />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </land-section>

    <!-- <section-foot /> -->
    <el-dialog
      :title="dlgForward.title"
      :visible.sync="dlgForward.visible"
      :width="dlgForward.width"
    >
      <el-form
        :ref="dlgForward.form"
        :model="forwardData"
        :rules="forwardRules"
        label-position="left"
        label-width="80px"
      >
        <el-form-item
          label="内容标题"
          prop="content"
        >
          <el-input v-model="forwardData.content" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button
          @click="onForwardCancel"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          @click="onForwardConfirm"
        >
          确定
        </el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import web from '@/api/web/co.web'

  import mixHotBanner from '@/pages/mixins/hot/mix.hot.banner'
  import mixHotBoot from '@/pages/mixins/hot/mix.hot.boot'

  export default {
    metaInfo: { title: '热门' },
    components: {
      HotActivities: () => import('@/pages/sections/hot/HotActivities.vue'),
      HotArticles: () => import('@/pages/sections/hot/HotArticles.vue'),
      HotImages: () => import('@/pages/sections/hot/HotImages.vue'),
      HotVideos: () => import('@/pages/sections/hot/HotVideos.vue'),
      // SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    mixins: [
      mixHotBanner,
      mixHotBoot
    ],
    data () {
      return {
      }
    },
    created () {
      this.initBannerParams({
        type: web.comm.BannerTypes.HOT_MAJOR
      })
      this.loadBanners()

      // this.configContents()
      // this.loadContents()
    },
    methods: {
    }
  }
</script>
