
import api from '@/api/co.api'
import web from '@/api/web/co.web'
import lib from '@/api/co.lib'
import mixPreset from '@/pages/mixins/mix.preset'

const parts = mixPreset.Parts
const names = mixPreset.Names

export default {
  data () {
    return {
      currTab: names.IMAGE,
      tabList: [
        parts[names.IMAGE],
        parts[names.VIDEO],
        parts[names.ARTICLE],
        parts[names.ACTIVITY]
      ],
      handlers: {
        like: this.toLike,
        forward: this.toForward,
        album: this.toAlbum
      },
      configers: {
        [names.IMAGE]: this.configContent,
        [names.VIDEO]: this.configContent,
        [names.ARTICLE]: this.configContent,
        [names.ACTIVITY]: this.configActivity
      },
      dlgForward: {
        visible: false,
        form: 'frmForward',
        title: '内容转发',
        width: '500px',
        item: null,
        index: -1
      },
      forwardData: {
        content: '',
      },
      forwardRules: {
        content: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    toLike (item, index) {
      const me = this
      const contentId = item.contentId
      const executed = function (res) {
        if (res.status) {
          me.$message({
            message: '点赞成功！',
            type: 'success'
          })
        } else {
          const err = res.data || {}
          if (err.code === 1001) {
            me.$message({
              message: '你再一次点赞成功！',
              type: 'success'
            })
          } else {
            me.$message({
              message: '点赞失败！',
              type: 'error'
            })
          }
        }
      }
      web.behaviour.like({
        contentId,
        executed
      })
    },
    onForwardCancel () {
      this.dlgForward.visible = false
    },
    onForwardConfirm () {
      const me = this
      const frm = this.$refs[this.dlgForward.form]
      if (frm) {
        frm.validate((valid) => {
          if (valid) {
            const item = me.dlgForward.item
            const contentId = item.contentId
            const executed = function (res) {
              // console.log({ res })
              if (res.status) {
                me.$message({
                  message: '内容转发成功！',
                  type: 'success'
                })
                me.dlgForward.visible = false
              } else {
                me.$message({
                  message: '内容转发失败！',
                  type: 'error'
                })
              }
            }

            web.behaviour.forward({
              contentId,
              forwardedText: me.forwardData.content,
              executed
            })
          } else {
            return false
          }
        })
      }
    },
    toForward (item, index) {
      this.dlgForward.item = item
      this.dlgForward.index = index
      this.dlgForward.visible = true
    },
    toAlbum (item, index) {

    },
    changePagination (number, cat) {
      const params = cat.params
      const data = cat.params.data
      const index = parseInt(number, 10) || 1

      cat.pagination.current = index
      data.currentPage = index
      params.reload = true
      api.httpx.getItems(params)
    },
    onAction (ev, navigation) {
      // console.log({ ev, navigation })
      const item = ev.item
      const index = ev.index
      const act = ev.action
      const handler = this.handlers[act.name]
      if (typeof handler === 'function') {
        handler(item, index)
      }
    },
    onHot (ev, navigation) {
      const item = ev.item
      this.toHot(item, navigation)
    },
    toHot (item, navigation) {
      const params = {
        name: navigation.name,
        contentId: item.contentId,
        type: item.type,
        title: item.title
      }

      const name = mixPreset.DetailPages.Hot
      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    toHots (ev, navigation) {
      if (!navigation) {
        return
      }

      const params = {
        name: navigation.name,
        title: navigation.title,
        type: navigation.type,
      }
      const name = mixPreset.ListPages.Hot
      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    configContent (obj) {
      const executed = function (res) {
        const data = obj.params.data
        const pagination = obj.pagination
        const pageSize = parseInt(data.pageSize) || 9
        pagination.length = Math.ceil(res.total / pageSize)
      }

      obj.params = web.content.getParams({
        type: obj.type,
        state: obj.state,
        forwardedFlag: obj.forwardedFlag,
        caches: obj.items,
        executed
      })
    },
    configActivity (obj) {
      const executed = function (res) {
        const data = obj.params.data
        const pagination = obj.pagination
        const pageSize = parseInt(data.pageSize) || 9
        pagination.length = Math.ceil(res.total / pageSize)
      }

      obj.params = web.activity.getParams({
        type: obj.type,
        state: obj.state,
        caches: obj.items,
        executed
      })
    },
    configContents () {
      for (const i in this.tabList) {
        const item = this.tabList[i]
        const handler = this.configers[item.name]
        if (typeof handler === 'function') {
          handler(item)
        }
      }
    },
    loadContents (reload = true) {
      for (const i in this.tabList) {
        const item = this.tabList[i]
        item.params.reload = reload
        api.httpx.getItems(item.params)
      }
    }
  }
}
